import React from "react"
import { Link, graphql } from "gatsby"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

import Layout from 'components/Layout'
import H1 from 'elements/H1'

import './blog.scss'

const richTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { title, description, file } = node.data.target.fields;
      const mimeType = file['en-US'].contentType
      const mimeGroup = mimeType.split('/')[0]

      switch (mimeGroup) {
        case 'image':
          return <img
            title={ title ? title['en-US'] : null}
            alt={description ?  description['en-US'] : null}
            src={file['en-US'].url}
          />
        case 'application':
          return <a
            alt={description ?  description['en-US'] : null}
            href={file['en-US'].url}
            >{ title ? title['en-US'] : file['en-US'].details.fileName }
          </a>
        default:
          return <span style={{backgroundColor: 'red', color: 'white'}}> {mimeType} embedded asset </span>
      }
      
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const fields = node.data.target.fields;
      switch (node.data.target.sys.contentType.sys.id) {
        case 'blockquote':
          return <div>
            <blockquote>
              {fields.quoteText['en-US']}
              <footer>
                <cite>{fields.quoter['en-US']}</cite>
              </footer>
            </blockquote>
          </div>
        default:
          return <div></div>
      }
    },
  }
}

export default function HomePage(props) {
  const document = props?.data?.contentfulBlog?.childContentfulBlogDescriptionRichTextNode?.json
  const Component = documentToReactComponents(document, richTextOptions)
  return (
    <Layout  isHome={true}>
      <div className="blog-block">
        <H1>{props?.data?.contentfulBlog?.title}</H1>
        { Component }
        <img src={props?.data?.contentfulBlog?.image?.file?.url} alt="" className="blog-img"/>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    contentfulBlog(id: { eq: $id }) {
      title
      image {
        file {
          url
        }
      }
      description {
        content {
          content {
            value
          }
        }
      }
      blogPath: gatsbyPath(filePath: "/blog/{ContentfulBlog.title}")
      childContentfulBlogDescriptionRichTextNode {
        json
      }
    }
  }`